import React, { Component } from 'react';

import { FooterWrapper } from './style';


class Footer extends Component{
	render(){
		return (
			<FooterWrapper>
				<div>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
				</div>
				<div>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
				</div>
				<div>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
				</div>
				<div>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
					<a href="#">联系我们</a>
				</div>
			</FooterWrapper>
		)
	}	
}

export default Footer;